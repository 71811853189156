import swal from "sweetalert";
import {
  FORBIDDEN_ACTION,
  GET_USER_DATA_ERROR,
  GET_USER_DATA_LOADING,
  GET_USER_DATA_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
  REGISTRATION_ERROR,
  REGISTRATION_LOADING,
  REGISTRATION_SUCCESS,
  SET_AUTH_REDUCER,
  UNAUTHORIZED_USER,
  USER_VERIFICATION_ERROR,
  USER_VERIFICATION_LOADING,
  USER_VERIFICATION_SUCCESS,
} from "../Types/AuthTypes";

const initState = {
  authError: "",
  authLoading: "",
  user: null,
  data: null,
  authErrorMessage: null,
  auth : null,

  registrationLoading: false,
  registrationSuccess: false,
  registrationError: false,
  registrationMessage: null,

  userVerificationLoading: false,
  userVerificationSuccess: false,
  userVerificationError: false,
  userVerificationMessage: null,

  logoutLoading: false,
  logoutError: false,
  logoutMessage: null,

  resetPasswordLoading: false,
  resetPasswordSuccess: false,
  resetPasswordError: false,
  resetPasswordMessage: null,

  changePasswordLoading: false,
  changePasswordSuccess: false,
  changePasswordError: false,
  changePasswordMessage: null,


  userData: null,
  userDataLoading: false,
  userDataError: false,
  userDataMessage: null,

};

const authReducer = (state = initState, action: any) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        authError: null,
        authLoading: true,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authError: null,
        authLoading: false,
        user: {
          ...action.payload,
          accessToken: action.payload?.accessToken,
          refreshAccessToken: action.payload?.refreshAccessToken,
        },
        data: action.payload?.userData,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        authError: true,
        authLoading: false,
        authErrorMessage: action?.message,
      };
    case UNAUTHORIZED_USER:
      if (action?.message === "Unauthorised access") {
        // swal("", "Session Expired!\nPlease login again", "info");
      } else {
        // swal("", action?.message || "Please Login again", "error").then(() => {
        //   // Redirect to login page
        //   window.location.href = "/login";
        // });
      }
      return {
        ...state,
        authLoading: false,
        user: null,
        auth : false,
        authErrorMessage: action?.message || "Please login again",
      };
    case FORBIDDEN_ACTION:
      // swal("", "Session Expired!\nPlease login again", "info").then(() => {
      //   // Redirect to login page
      //   window.location.href = "/login";
      // });
      return {
        ...state,
        authLoading: false,
        user: null,
        auth : false,
        authErrorMessage: action?.message || "Please login again",
      };
    case REGISTRATION_LOADING:
      return {
        ...state,
        registrationLoading: true,
        registrationSuccess: false,
        registrationError: false,
        registrationMessage: null,
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationLoading: false,
        registrationSuccess: true,
        registrationError: false,
        registrationMessage:
          action?.message ||
          "Successfully Registered! Now check your to validate your user account",
      };
    case SET_AUTH_REDUCER:
      return { ...state, ...action.payload };
    case REGISTRATION_ERROR:
      return {
        ...state,
        registrationLoading: false,
        registrationSuccess: false,
        registrationError: true,
        registrationMessage: action?.message || "Failed to register",
      };
    case USER_VERIFICATION_LOADING:
      return {
        ...state,
        userVerificationLoading: true,
        userVerificationSuccess: false,
        userVerificationError: false,
        userVerificationMessage: null,
      };
    case USER_VERIFICATION_SUCCESS:
      return {
        ...state,
        userVerificationLoading: false,
        userVerificationSuccess: true,
        userVerificationError: false,
        userVerificationMessage: action.message,
      };
    case USER_VERIFICATION_ERROR:
      return {
        ...state,
        userVerificationLoading: false,
        userVerificationSuccess: false,
        userVerificationError: true,
        userVerificationMessage: action.message,
      };
    case LOGOUT_LOADING:
      return {
        ...state,
        logoutLoading: true,
        logoutError: false,
        logoutMessage: null,
      };
    case LOGOUT_SUCCESS:
      return { ...state, user: null, data: null };
    case LOGOUT_ERROR:
      swal("Oops!", action.message || "Logout Error", "error");
      return { ...state };
    case GET_USER_DATA_SUCCESS : 
  return {
    ...state,
    userData: action.payload
  }
  case GET_USER_DATA_LOADING : 
  return {
    ...state,
    userData: null,
    userDataLoading : true,
    userDataError : false
  }

    case GET_USER_DATA_ERROR :  
  return {
    ...state,
    userData: null,
    userDataLoading : false,
    userDataError : true
  }
    default:
      return state;
  }
};

export default authReducer;
