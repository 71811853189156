//Route Paths
export const home_path = "/";
export const login_path = "/login";
export const dashboard_path = "dashboard";
export const register_path = "/register";
export const forgot_path = "/forgot";
export const reset_path = "/reset";
export const profile_path = "profile";
export const privacy_policy_path = "/privacy-policy";
export const terms_condition_path = "/terms-condition";
export const contact_us_path = "/contact";

export const paytrend_tool_path = "/paytrend";
export const static_report_tool_path = "/static-report";
export const static_report_store_path = "/static-report-store";
export const blog_path = "/blog";
export const payment_success_url = "/success";
export const payment_cancel_url = "/canceled";

export const backendUrl = "https://paytrendbackend.humello.com";
// export const backendUrl = "http://localhost:8002";
export const domain_url = "https://www.humello.com";
export const frontendUrl = "https://paytrend.humello.com";
// export const frontendUrl = "http://localhost:3001";

export const method_get = "get";
export const method_post = "post";
export const method_put = "put";



