export type ActionType =
  | { type: typeof SET_SALARY_DASHBOARD; payload: any }
  | { type: typeof CHART1_LOADING }
  | { type: typeof CHART1_SUCCESS; sql: any; payload: any; skill: any }
  | { type: typeof CHART1_ERROR }
  | { type: typeof CHART2_LOADING }
  | { type: typeof CHART2_SUCCESS; sql: any; payload: any; skill: any }
  | { type: typeof CHART2_ERROR }
  | { type: typeof CHART3_LOADING }
  | { type: typeof CHART3_SUCCESS; sql: any; payload: any; skill: any }
  | { type: typeof CHART3_ERROR }
  | { type: typeof CHART4_LOADING }
  | { type: typeof CHART4_SUCCESS; sql: any; payload: any; skill: any }
  | { type: typeof CHART4_ERROR }
  | { type: typeof CHART5_LOADING }
  | { type: typeof CHART5_SUCCESS; sql: any; payload: any; skill: any }
  | { type: typeof CHART5_ERROR }
  | { type: typeof CHART6_LOADING }
  | { type: typeof CHART6_SUCCESS; sql: any; payload: any; skill: any }
  | { type: typeof CHART6_ERROR }
  | { type: typeof CHART7_LOADING }
  | { type: typeof CHART7_SUCCESS; sql: any; payload: any; skill: any }
  | { type: typeof CHART7_ERROR }
  | { type: typeof AGGREGATED_LOADING }
  | { type: typeof AGGREGATED_SUCCESS; sql: any; payload: any; skill: any }
  | { type: typeof AGGREGATED_ERROR }
  | { type: typeof FETCH_INDUSTRY_LOADING }
  | { type: typeof FETCH_INDUSTRY_SUCCESS; payload: any }
  | { type: typeof FETCH_INDUSTRY_ERROR }
  | { type: typeof FETCH_LOCATION_LOADING }
  | { type: typeof FETCH_LOCATION_SUCCESS; payload: any }
  | { type: typeof FETCH_LOCATION_ERROR }
  // Define types for role
  | { type: typeof FETCH_ROLE_LOADING }
  | { type: typeof FETCH_ROLE_SUCCESS; payload: any }
  | { type: typeof FETCH_ROLE_ERROR }
  // Define types for department
  | { type: typeof FETCH_DEPARTMENT_LOADING }
  | { type: typeof FETCH_DEPARTMENT_SUCCESS; payload: any }
  | { type: typeof FETCH_DEPARTMENT_ERROR }
  // Define types for role category
  | { type: typeof FETCH_ROLE_CATEGORY_LOADING }
  | { type: typeof FETCH_ROLE_CATEGORY_SUCCESS; payload: any }
  | { type: typeof FETCH_ROLE_CATEGORY_ERROR }
  // Define types for skill
  | { type: typeof FETCH_SKILL_LOADING }
  | { type: typeof FETCH_SKILL_SUCCESS; payload: any }
  | { type: typeof FETCH_SKILL_ERROR }
  // Define types for skill
  | { type: typeof FETCH_VALID_INPUT_LOADING }
  | { type: typeof FETCH_VALID_INPUT_SUCCESS; payload: any }
  | { type: typeof FETCH_VALID_INPUT_ERROR };

export const SET_SALARY_DASHBOARD = "SET_SALARY_DASHBOARD";

export const CHART1_LOADING = "CHART1_LOADING";
export const CHART1_SUCCESS = "CHART1_SUCCESS";
export const CHART1_ERROR = "CHART1_ERROR";

export const CHART2_LOADING = "CHART2_LOADING";
export const CHART2_SUCCESS = "CHART2_SUCCESS";
export const CHART2_ERROR = "CHART2_ERROR";

export const CHART3_LOADING = "CHART3_LOADING";
export const CHART3_SUCCESS = "CHART3_SUCCESS";
export const CHART3_ERROR = "CHART3_ERROR";

export const CHART4_LOADING = "CHART4_LOADING";
export const CHART4_SUCCESS = "CHART4_SUCCESS";
export const CHART4_ERROR = "CHART4_ERROR";

export const CHART5_LOADING = "CHART5_LOADING";
export const CHART5_SUCCESS = "CHART5_SUCCESS";
export const CHART5_ERROR = "CHART5_ERROR";

export const CHART6_LOADING = "CHART6_LOADING";
export const CHART6_SUCCESS = "CHART6_SUCCESS";
export const CHART6_ERROR = "CHART6_ERROR";

export const CHART7_LOADING = "CHART7_LOADING";
export const CHART7_SUCCESS = "CHART7_SUCCESS";
export const CHART7_ERROR = "CHART7_ERROR";


export const AGGREGATED_LOADING = "AGGREGATED_LOADING";
export const AGGREGATED_SUCCESS = "AGGREGATED_SUCCESS";
export const AGGREGATED_ERROR = "AGGREGATED_ERROR";

export const FETCH_INDUSTRY_LOADING = "FETCH_INDUSTRY_LOADING";
export const FETCH_INDUSTRY_SUCCESS = "FETCH_INDUSTRY_SUCCESS";
export const FETCH_INDUSTRY_ERROR = "FETCH_INDUSTRY_ERROR";

export const FETCH_LOCATION_LOADING = "FETCH_LOCATION_LOADING";
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_ERROR = "FETCH_LOCATION_ERROR";

// Constants for role actions
export const FETCH_ROLE_LOADING = "FETCH_ROLE_LOADING";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_ERROR = "FETCH_ROLE_ERROR";

// Constants for department actions
export const FETCH_DEPARTMENT_LOADING = "FETCH_DEPARTMENT_LOADING";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
export const FETCH_DEPARTMENT_ERROR = "FETCH_DEPARTMENT_ERROR";

// Constants for role category actions
export const FETCH_ROLE_CATEGORY_LOADING = "FETCH_ROLE_CATEGORY_LOADING";
export const FETCH_ROLE_CATEGORY_SUCCESS = "FETCH_ROLE_CATEGORY_SUCCESS";
export const FETCH_ROLE_CATEGORY_ERROR = "FETCH_ROLE_CATEGORY_ERROR";

// Constants for skill actions
export const FETCH_SKILL_LOADING = "FETCH_SKILL_LOADING";
export const FETCH_SKILL_SUCCESS = "FETCH_SKILL_SUCCESS";
export const FETCH_SKILL_ERROR = "FETCH_SKILL_ERROR";

// Constants for skill actions
export const FETCH_VALID_INPUT_LOADING = "FETCH_VALID_INPUT_LOADING";
export const FETCH_VALID_INPUT_SUCCESS = "FETCH_VALID_INPUT_SUCCESS";
export const FETCH_VALID_INPUT_ERROR = "FETCH_VALID_INPUT_ERROR";
