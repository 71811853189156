export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const UNAUTHORIZED_USER = "UNAUTHORIZED_USER";
export const FORBIDDEN_ACTION = "FORBIDDEN_ACTION";

export const REGISTRATION_LOADING = "REGISTRATION_LOADING";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";

export const SET_AUTH_REDUCER = "SET_AUTH_REDUCER";

export const USER_VERIFICATION_LOADING = "USER_VERIFICATION_LOADING";
export const USER_VERIFICATION_SUCCESS = "USER_VERIFICATION_SUCCESS";
export const USER_VERIFICATION_ERROR = "USER_VERIFICATION_ERROR";

export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_ERROR = "TOKEN_REFRESH_ERROR";

export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";


export const GET_USER_DATA_LOADING = "GET_USER_DATA_LOADING";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";


