import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";
import { LoadingOutlined } from "@ant-design/icons";
import { primaryColor, profileThreshold } from "../../Config/constants";
import { Progress } from "antd";
import { useApplicationContext } from "../../Context/app-context";
interface DataType {
  minSalary: number;
  avgSalary: number;
  maxSalary: number;
}

interface Employee {
  salary: number;
}
export const calculateMaxSalaryIndex = (data: Employee[]) => {
  // Sorting the data in descending order of salary
  const sortedData = data.slice().sort((a, b) => b.salary - a.salary);

  let maxValue = sortedData[0].salary;

  for (let i = 0; i < sortedData.length; i++) {
    let value = sortedData[i].salary;

    const count = sortedData.reduce((acc, curr) => {
      return curr.salary === value ? acc + 1 : acc;
    }, 0);

    if (count > 2) {
      maxValue = value;
      break;
    }
  }

  const arr = data.filter((item) => item.salary <= maxValue);

  return arr;
};

export const calculateSalaryStats = (data: Employee[]) => {
  if (data.length === 0) {
    return {
      minSalary: 0,
      avgSalary: 0,
      maxSalary: 0,
    };
  }

  // Sorting the data in descending order of salary
  const sortedData = data.slice().sort((a, b) => b.salary - a.salary);
  let minSalary = Math.min(...data?.map((data) => data.salary));
  let maxSalary = Math.max(...data?.map((data) => data.salary));

  let totalSalary = 0;

  const salaryOccurrences: { [key: number]: number } = {};
  sortedData.forEach((employee) => {
    totalSalary += employee.salary;
    salaryOccurrences[employee.salary] = salaryOccurrences[employee.salary]
      ? salaryOccurrences[employee.salary] + 1
      : 1;
  });

  const avgSalary = totalSalary / sortedData.length;

  return {
    minSalary,
    avgSalary,
    maxSalary,
  };
};
const SalaryLinearChart = () => {
  const [data, setData] = useState<DataType>({
    minSalary: 0,
    avgSalary: 0,
    maxSalary: 0,
  });
  const { isMobile } = useApplicationContext();

  const chart1 = useSelector((state: any) => state.salaryDashboard.chart1);
  const chart2 = useSelector((state: any) => state.salaryDashboard.chart2);

  const chart1Success = useSelector(
    (state: any) => state.salaryDashboard.chart1success
  );
  const chart1Loading = useSelector(
    (state: any) => state.salaryDashboard.chart2loading
  );

  useEffect(() => {
    if (chart1 && chart2?.length > profileThreshold) {
      const array = calculateMaxSalaryIndex(chart2);
      const salaryStats = calculateSalaryStats(array);
      setData({
        minSalary: convertToLakhsPerAnnum(salaryStats?.minSalary),
        avgSalary: convertToLakhsPerAnnum(salaryStats?.avgSalary),
        maxSalary: convertToLakhsPerAnnum(salaryStats?.maxSalary),
      });
    }
  }, [chart1, chart1Success, chart2]);

  useEffect(() => {
    if (chart1Loading) {
      setData({ minSalary: 0, avgSalary: 0, maxSalary: 0 });
    }
  }, [chart1Loading]);

  return (
    <div>
      <p className="chart-heading">Salary Range</p>
      {chart1Loading ? (
        <div className="graph-nodata-div-linear">
          <LoadingOutlined className="loader primary-color" />
        </div>
      ) : (
        <div className="d-flex justify-content-around">
          <div style={{ textAlign: "center" }}>
            <Progress
              className="mb-1"
              strokeColor={primaryColor}
              strokeWidth={8}
              size={isMobile ? 80 : 120}
              type="circle"
              percent={100}
              format={() => (
                <div>
                  <span style={{}} className="percentile-value salary-value">
                    {data?.minSalary}
                  </span>
                  <span className="text-dark">LPA</span>
                </div>
              )}
            />
            <p>Minimum Salary</p>
          </div>

          <div style={{ textAlign: "center" }}>
            <Progress
              className="mb-1 "
              strokeColor={primaryColor}
              strokeWidth={8}
              size={isMobile ? 80 : 120}
              type="circle"
              percent={100}
              format={() => (
                <div>
                  <span style={{}} className="percentile-value salary-value">
                    {data?.avgSalary}
                  </span>
                  <span className="text-dark">LPA</span>
                </div>
              )}
            />
            <p>Average Salary</p>
          </div>
          <div style={{ textAlign: "center" }}>
            <Progress
              className="mb-1"
              strokeColor={primaryColor}
              strokeWidth={8}
              size={isMobile ? 80 : 120}
              type="circle"
              percent={100}
              format={() => (
                <div>
                  <span style={{}} className="percentile-value salary-value">
                    {data?.maxSalary}
                  </span>
                  <span className="text-dark">LPA</span>
                </div>
              )}
            />
            <p>Maximum Salary</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalaryLinearChart;
