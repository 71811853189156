"use client";

import { slugify, truncateText } from "../../Utils/commonHelperFunctions";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ blog }: { blog: any }) => {
  const navigate = useNavigate();
  return (
    <div className="border p-0 ">
      <img
        src={blog.imgPreview}
        alt={blog.main}
        className=""
        height={300}
        width={"100%"}
      />
      <div className="p-3">
        <h3
          onClick={() => navigate(`/blog/${slugify(blog.main)}`)}
          className="mb-3 "
          style={{ height: "70px", fontWeight: "bold", cursor: "pointer" }}
        >
          {blog.main}
        </h3>
        <p style={{ minHeight: "120px" }}>{truncateText(blog.subMain, 150)}</p>
        <div
          className=""
          onClick={() => navigate(`/blog/${slugify(blog.main)}`)}
        >
          <span className="text-primary h5" style={{ cursor: "pointer" }}>
            Read more <RightOutlined />
          </span>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
