import React, { useState } from "react";
import { Button, Form, Input, Select, Modal, Typography, Col, Row } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LogoComponent from "../LogoComponent";
import { backendUrl } from "../../Config/config";
import FooterComponent from "../../Layout/Footer";

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const ContactPage: React.FC = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    try {
      // Replace with your actual API endpoint
      await axios.post(`${backendUrl}/api/enquiry/send`, values);

      form.resetFields();
      setIsModalVisible(true);

      // Auto-close the modal after 3 seconds
      setTimeout(() => setIsModalVisible(false), 3000);
    } catch (error) {
      console.error("Form submission error:", error);
      // Handle error appropriately in real application
    }
  };

  return (
    <>
      <div className=" border-bottom py-3">
        <div className=" d-flex justify-content-between container">
          <h1
            className="m-0 text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Paytrend
          </h1>
          <LogoComponent />
        </div>
      </div>
      <Col
        className="section "
        style={{ marginTop: "0px", paddingBottom: "20px", minHeight: "85vh" }}
      >
        <Col
          md={14}
          style={{ margin: "0 auto", paddingTop: "10PX" }}
          className="container"
        >
          <Title level={2} className="text-center section-title">
            CONTACT US
          </Title>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    { required: true, message: "Please enter your first name" },
                  ]}
                >
                  <Input className="primary-input" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    { required: true, message: "Please enter your last name" },
                  ]}
                >
                  <Input className="primary-input" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your email" },
                    { type: "email", message: "Please enter a valid email" },
                  ]}
                >
                  <Input className="primary-input" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                    {
                      pattern: /^\d{10}$/,
                      message: "Phone number must be exactly 10 digits",
                    },
                  ]}
                >
                  <Input maxLength={10} className="primary-input" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: "Please enter your message" }]}
            >
              <TextArea rows={4} className="primary-input" />
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Modal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            closable={false}
          >
            <div style={{ textAlign: "center" }}>
              <MailOutlined style={{ fontSize: "3rem", color: "#1890ff" }} />
              <Title level={3}>Thank you for reaching out!</Title>
              <Paragraph>We will get back to you shortly.</Paragraph>
            </div>
          </Modal>
        </Col>
      </Col>
      <FooterComponent />
    </>
  );
};

export default ContactPage;
