import { Layout, Typography, Space } from "antd";
import { contact_us_path } from "../Config/config";

const { Footer } = Layout;
const { Text } = Typography;

const FooterComponent = () => {
  return (
    <Footer className="footer ">
      <Space direction="vertical" className="footer-content container  ">
        <a href="/" rel="noopener noreferrer">
          <span className="footer-brand">Humello © 2024</span>
        </a>{" "}
        <Space size="large">
          <a href={contact_us_path}>
            <Text className="footer-link">Contact</Text>
          </a>
        </Space>
      </Space>
    </Footer>
  );
};

export default FooterComponent;
