import axios from 'axios';

// Replace with your actual Anthropic API key

const HUGGINGFACE_API_KEY = "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";

const generateDescription = async (title: string, setDescription: React.Dispatch<React.SetStateAction<string>>, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://api-inference.huggingface.co/models/nisten/Biggie-SmoLlm-0.15B-Base',
        { inputs: `${title} is a rapidly evolving field that encompasses` },
        {
          headers: {
            'Authorization': `Bearer ${HUGGINGFACE_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      setDescription(response.data[0].generated_text);

    } catch (error) {
      console.error("Error generating description:", error);

    } finally {
      setLoading(false);
    }
  };

export default generateDescription;