import { useRef } from "react";
import AverageSalaryChart from "./AverageSalaryTrend";
import { Card } from "antd";
import AverageSalaryExperienceChart from "./AverageSalExpChart";
import MostCommonSkillsChart from "./MostCommonSkillChart";
import AverageSalarySkillsChart from "./AverageSalarySkillsChart";

import SalaryPercentileChart from "./SalaryPercentileChart";
import { useApplicationContext } from "../../Context/app-context";
import SalaryDistributionChart from "./ SalaryDistributionChart";
import { useSelector } from "react-redux";
import DemoScatter from "./ScatterPlot";

function capitalizeWords(str: string) {
  // Split the string into words
  const words = str.split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back into a string
  return capitalizedWords.join(" ");
}
const PaytrendOutput = () => {
  const { selectedJobRole, isMobile, averageValue } = useApplicationContext();
  const printAreaRef = useRef(null);

  //eslint-disable-next-line
  const generatePDF = () => {
    const printContents = printAreaRef.current as HTMLElement | null; // Explicitly define the type

    if (printContents) {
      const wrapperDiv = document.createElement("div");
      wrapperDiv.style.position = "relative";

      // Append the printContent directly
      wrapperDiv.appendChild(printContents.cloneNode(true));

      // Define watermark positions for text (you can customize as needed)
      const textWatermarkPositions = [
        { top: "2.3%", left: "90%", rotation: "0deg" },
      ];

      // Create text watermarks and position them
      textWatermarkPositions.forEach((position) => {
        const textWatermarkDiv = document.createElement("div");
        textWatermarkDiv.style.position = "absolute";
        textWatermarkDiv.style.top = position.top;
        textWatermarkDiv.style.left = position.left;
        textWatermarkDiv.style.transform = `translate(-50%, -50%) rotate(${position.rotation})`;
        textWatermarkDiv.style.color = "rgba(0, 0, 0, 0.2)";
        textWatermarkDiv.style.fontSize = "24px";
        textWatermarkDiv.innerText = "Humello";

        // Append each text watermark to the wrapper
        wrapperDiv.appendChild(textWatermarkDiv);
      });

      // Apply styles for print media to avoid box-shadow effect
      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText =
        "@media print { .custom-shadow { box-shadow: none !important; } @page { margin: 0; } @page :first { display: none; } }";
      wrapperDiv.appendChild(styleSheet);

      // Append wrapperDiv to the body
      document.body.appendChild(wrapperDiv);

      // Print the page
      window.print();

      // Remove wrapperDiv from the body after printing
      document.body.removeChild(wrapperDiv);
    } else {
      console.error("Element not found.");
    }
  };
  const skillBoolean = useSelector(
    (state: any) => state.salaryDashboard.skill2
  );
  const chart2 = useSelector((state: any) => state.salaryDashboard.chart2);
  //eslint-disable-next-line
  const chart2Loading = useSelector(
    (state: any) => state.salaryDashboard.chart2Loading
  );

  return (
    <>
      {chart2 ? (
        <div className="container p-0 px-lg-3 py-2 py-lg-3" ref={printAreaRef}>
          <Card className="m-1 mlg-2  top-header-card ">
            <div className="d-lg-flex justify-content-center align-items-center">
              <div className="m-0 w-100 ">
                {selectedJobRole ? (
                  <div className="">
                    {" "}
                    <h2>
                      {capitalizeWords(selectedJobRole).toUpperCase()} SALARY
                      REPORT
                    </h2>
                    {averageValue && (
                      <h3 style={{ color: "gray" }}>
                        Average Salary :{" "}
                        <span className="text-primary">{averageValue} LPA</span>{" "}
                      </h3>
                    )}
                    {skillBoolean !== null ? (
                      skillBoolean ? (
                        ""
                      ) : (
                        <p className="text-primary">
                          The results below do not incorporate the selected
                          skill(s){" "}
                        </p>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div>
                    {" "}
                    <h2> SALARY REPORT</h2>
                  </div>
                )}
              </div>
              {/* {selectedJobRole && (
                <Button
                  className="btn-primary w-100 d-none"
                  onClick={generatePDF}
                >
                  Download <DownloadOutlined />{" "}
                </Button>
              )} */}
            </div>
            {!selectedJobRole && !isMobile && (
              <p className="m-0 mb-2 salary-value">
                Welcome! let's get started. Fill in the inputs to generate your
                personalized salary report. Happy exploring!
              </p>
            )}

            {/* <p className="">
          The charts below show data for roles in Bangalore with  year(s) of
          experience and selected skill(s)
        </p> */}
          </Card>

          <div className="d-lg-flex">
            <Card className="shadow chart-full ">
              <SalaryPercentileChart />
            </Card>
          </div>
          <div className="d-lg-flex">
            <Card className="shadow  chart">
              <DemoScatter />
            </Card>
            <Card className="shadow chart">
              <AverageSalaryChart />
            </Card>
          </div>

          <div className="d-lg-flex">
            <Card className="shadow chart">
              <SalaryDistributionChart />
            </Card>
            <Card className="shadow chart">
              <AverageSalaryExperienceChart />
            </Card>
          </div>
          <div className="d-lg-flex">
            <Card className="shadow chart">
              <MostCommonSkillsChart />
            </Card>
            <Card className="shadow chart">
              <AverageSalarySkillsChart />
            </Card>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PaytrendOutput;
