import "./App.css";
import AppRoute from "./Routes/AppRoute";
import { ConfigProvider } from "antd";
import { AppContextProvider } from "./Context/app-context";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#0191b4",
          },
        }}
      >
        <AppContextProvider>
          <AppRoute />
        </AppContextProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
