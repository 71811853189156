export function slugify(text: string) {
    return text
      ?.toString()
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }
  
  
  
  export const unSlugify = (text : string) => {
    const decodedName = text?.replace(/-/g, " ");
    return decodedName.charAt(0).toUpperCase() + decodedName.slice(1);
  };
  


export const truncateText = (text : string, length : number) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };
  