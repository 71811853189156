import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  brandColors,
  chartBorderColor,
  primaryColor,
} from "../../Config/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";
import { useSelector } from "react-redux";

interface DataType {
  period: string;
  salary: number;
}

const AverageSalaryChart = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [percentageChange, setPercentageChange] = useState<number | null>(null);
  const chart3 = useSelector((state: any) => state.salaryDashboard.chart3);
  const chart2 = useSelector((state: any) => state.salaryDashboard.chart2);
  const chart3Success = useSelector(
    (state: any) => state.salaryDashboard.chart3success
  );
  const chart3Loading = useSelector(
    (state: any) => state.salaryDashboard.chart3loading
  );

  useEffect(() => {
    if (chart3 && chart3Success && chart2.length > 10) {
      // Convert chart3 data into desired format
      const medianSalaryData = chart3.map((item: any) => {
        return {
          period: `Last ${item.days}`,
          salary: convertToLakhsPerAnnum(item.median),
        };
      });

      medianSalaryData?.reverse();

      if (medianSalaryData.length > 0 && medianSalaryData[1].salary === 0) {
        setData([]);
      } else if (
        medianSalaryData.length > 0 &&
        medianSalaryData[2].salary === 0
      ) {
        const filteredData = medianSalaryData.slice(0, -1);
        setData(filteredData);
      } else {
        setData(medianSalaryData);
      }

      // Calculate percentage change
      if (medianSalaryData.length > 1) {
        const initialSalary = medianSalaryData[0].salary;
        const finalSalary =
          medianSalaryData[medianSalaryData.length - 1].salary;
        const change = ((finalSalary - initialSalary) / initialSalary) * 100;
        setPercentageChange(change);
      } else {
        setPercentageChange(null);
      }
    }
  }, [chart3, chart3Success]);

  // Calculate min and max salary values
  const maxSalary = Math.max(...data?.map((data) => data.salary));

  useEffect(() => {
    if (chart3Loading) {
      setData([]);
    }
  }, [chart3Loading]);

  const series = [
    {
      name: "Average Salary (LPA)",
      data: data.map((item) => item.salary),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "area", // Changed from "line" to "area"

      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      curve: "smooth", // Curve adjusted for area chart
      fill: {
        type: "solid",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
    },
    fill: {
      type: "gradient", // Added fill settings for area chart
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels on data points
    },
    colors: [brandColors[3]],
    grid: {
      borderColor: chartBorderColor,
      strokeDashArray: 3,
      padding: {
        top: -10,
        bottom: 8,
        left: 10,
        right: 10,
      },
    },
    xaxis: {
      categories: data.map((item) => item.period),
      title: {
        text: "Time",
      },
    },
    yaxis: {
      title: {
        text: "Average Salary (LPA)",
      },
      min: 0,
      max: maxSalary + 5, // Adjusted maximum to create some padding
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) => `${value} LPA`,
      },
    },

    markers: {
      size: 4,
      colors: brandColors[2],
    },
    // grid: {
    //   borderColor: "#e7e7e7",
    //   row: {
    //     colors: ["#f3f3f3", "transparent"], // alternating background colors for rows
    //     opacity: 0.5,
    //   },
    // },
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: primaryColor,
        fontSize: "14px",
      },
    },
  };

  return (
    <div>
      <p className="chart-heading"> Average salary trend</p>

      {data.length > 0 ? (
        <div className="d-flex justify-content-center">
          <Chart
            options={options}
            series={series}
            type="area"
            height={300}
            width={450}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          {chart3Loading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AverageSalaryChart;
