import React, { useState } from "react";
import { Form, Select, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../Store/Action/CommonAction";
import { method_post } from "../../Config/config";
import { useApplicationContext } from "../../Context/app-context";
import { AppDispatch } from "../../Store/Types/CommonTypes";
import {
  AGGREGATED_ERROR,
  AGGREGATED_LOADING,
  AGGREGATED_SUCCESS,
} from "../../Store/Types/SalaryDashBoardReducerTypes";
import generateDescription from "../../Utils/GenerateDescriptionAI";
import { capitalizeWord } from "../../Utils/PaytrendHelperFunctions";

const { Option } = Select;
const { TextArea } = Input;

const StaticReportInput = ({
  setShowMobileMenu,
}: {
  setShowMobileMenu: any;
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { setSelectedJobRole } = useApplicationContext();
  const accessToken = localStorage.getItem("accessToken");
  const roles = useSelector((state: any) => state.salaryDashboard.role);
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState<string | undefined>(
    undefined
  );

  const onFinish = (values: any) => {
    setSelectedJobRole(values?.jobTitle);
    setShowMobileMenu(false);

    // Redirect to the updated URL
    window.history.replaceState(
      { path: window.location.href },
      "",
      window.location.href
    );

    // Submit the form data and description to the backend
    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/static-report",
        { jobTitle: values?.jobTitle, description },
        AGGREGATED_LOADING,
        AGGREGATED_SUCCESS,
        AGGREGATED_ERROR,
        accessToken
      )
    );
  };

  const handleTitleChange = (value: string) => {
    setSelectedTitle(value);
  };

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical" className="form">
        <Form.Item
          name="jobTitle"
          label="Job Title"
          rules={[{ required: true, message: "Please select a job title!" }]}
        >
          <Select
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            filterOption={(input, option) => {
              const children = option?.children as unknown as string;
              return children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            className="primary-input"
            onChange={handleTitleChange}
          >
            {roles
              ?.sort((a: any, b: any) => {
                const roleA = a.role.toLowerCase();
                const roleB = b.role.toLowerCase();

                if (roleA < roleB) {
                  return -1;
                }
                if (roleA > roleB) {
                  return 1;
                }
                return 0;
              })
              .map((role: any) => (
                <Option key={role.id} value={role.role}>
                  {role.role}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Role Description"
          rules={[
            { required: true, message: "Please enter a role description" },
          ]}
        >
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={6}
            placeholder="Enter or generate a detailed description for the job title"
          />
          <Button
            type="default"
            onClick={() =>
              selectedTitle &&
              generateDescription(
                capitalizeWord(selectedTitle),
                setDescription,
                setLoading
              )
            }
            loading={loading}
            style={{ marginTop: 8 }}
            disabled={!selectedTitle}
          >
            Generate Description with AI
          </Button>
        </Form.Item>

        <div className="row">
          <Form.Item className="col-7 pr-2">
            <Button
              type="primary"
              className="btn-primary"
              htmlType="submit"
              style={{ width: "100%" }}
              disabled={!selectedTitle || !description}
            >
              Submit
            </Button>
          </Form.Item>

          <Form.Item className="col-5 pl-2">
            <Button
              className="btn-primary"
              onClick={(e) => {
                e.preventDefault();
                form.resetFields();
                setDescription("");
                setSelectedTitle(undefined);
              }}
              style={{ width: "100%" }}
            >
              Reset
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default StaticReportInput;
