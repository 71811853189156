import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { LoadingOutlined } from "@ant-design/icons";
import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";
import { chartBorderColor, primaryColor } from "../../Config/constants";
import { useSelector } from "react-redux";

interface DataType {
  skill: string;
  salary: number;
}

const AverageSalarySkillsChart = () => {
  const [data, setData] = useState<DataType[]>([]);

  const dataSet = useSelector((state: any) => state.salaryDashboard.chart5);

  const dataSetLoading = useSelector(
    (state: any) => state.salaryDashboard.chart5loading
  );

  useEffect(() => {
    if (dataSet) {
      const newData = dataSet.map((item: DataType) => ({
        ...item,
        skill: item.skill.charAt(0).toUpperCase() + item.skill.slice(1),
        salary: convertToLakhsPerAnnum(item.salary),
      }));
      setData(newData);
    }
  }, [dataSet]);

  useEffect(() => {
    if (dataSetLoading) {
      setData([]);
    }
  }, [dataSetLoading]);

  // Custom colors for each bar
  const barColors = [primaryColor, "#f8d90f", "#d3dd18", "#fe7a15", "#007bff"];
  const chartSeries = [
    {
      name: "Average Salary",
      data: data.map((item) => ({
        x: item.skill,
        y: item.salary,
        fillColor: barColors[data.indexOf(item) % barColors.length], // Apply custom color to each bar
      })),
    },
  ];

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10, // Rounded bars
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        columnWidth: "60%",
        dataLabels: {
          position: "top", // Position data labels
        },
      },
    },
    colors: barColors, // This will apply colors in order
    grid: {
      borderColor: chartBorderColor,
      strokeDashArray: 3,
      padding: {
        top: -10,
        bottom: 8,
        left: 30,
        right: 30,
      },
    },
    xaxis: {
      categories: data.map((item) => item.skill),
      title: {
        text: "Skill",
      },
    },
    yaxis: {
      title: {
        text: "Average Salary (LPA)",
      },
      min: 0,
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${value.toLocaleString()} LPA`,
      },
    },

    dataLabels: {
      enabled: false,
      formatter: (value: number) => `${value.toLocaleString()} LPA`,
    },
  };

  return (
    <div>
      <p className="chart-heading">Average Salary for Skills</p>

      {data.length > 0 ? (
        <div>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={300}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          {dataSetLoading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AverageSalarySkillsChart;
