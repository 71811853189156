import { Reducer } from "redux";
import { ActionTypeCommon, SET_COMMON_REDUCER } from "../Types/CommonTypes";

const initState = {
  salaryDashboard: {
    // experience: ,
    // location: "Sydney NSW",
    // filterType: true,
  },
};

const commonReducer: Reducer<typeof initState, ActionTypeCommon> = (
  state = initState,
  action
) => {
  //   console.log(action);
  switch (action.type) {
    case SET_COMMON_REDUCER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
