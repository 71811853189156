import React from "react";
import { Link } from "react-router-dom"; // If you're using React Router for navigation

const CancelPage = () => {
  return (
    <div className="container cancel-page-container">
      <h1 className="heading">Your Purchase Has Been Canceled</h1>
      <p className="message">We're sorry to see you cancel your purchase.</p>
      <p className="message">
        If you have any questions or need assistance, feel free to{" "}
        <a href="https://humello.com/#contact" className="link">
          contact our support team
        </a>
        .
      </p>
      <Link to="/" className="link">
        Return to Homepage
      </Link>
    </div>
  );
};

export default CancelPage;
