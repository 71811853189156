import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { LoadingOutlined } from "@ant-design/icons";
import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";
import {
  brandColors,
  chartBorderColor,
  primaryColor,
} from "../../Config/constants";

interface DataType {
  experience: number;
  salary: number;
}

const AverageSalaryExperienceChart = ({
  dataSet,
  dataSetLoading,
  title,
  location,
}: {
  dataSet: any;
  dataSetLoading: boolean;
  title: string;
  location: string;
}) => {
  const [data, setData] = useState<DataType[]>([]);
  const [highestSalary, setHighestSalary] = useState<number | null>(null);
  const [lowestSalary, setLowestSalary] = useState<number | null>(null);
  const [averageSalary, setAverageSalary] = useState<number | null>(null);

  const chart6Loading = dataSetLoading;
  const chart6 = dataSet;

  useEffect(() => {
    if (chart6) {
      // Sort the data based on salary values in ascending order
      const sortedData = [...chart6].sort((a, b) => a.salary - b.salary);

      // Rearrange the experience values to ensure increasing trend
      const adjustedData = sortedData.map((item, index, array) => ({
        experience: index + 1,
        salary: convertToLakhsPerAnnum(item.salary),
      }));
      setData(adjustedData);

      // Calculate insights
      if (adjustedData.length > 0) {
        const salaries = adjustedData.map((item) => item.salary);
        setHighestSalary(Math.max(...salaries));
        setLowestSalary(Math.min(...salaries));
        setAverageSalary(
          Math.floor(
            salaries.reduce((acc, curr) => acc + curr, 0) / salaries.length
          )
        );
      }
    }
  }, [chart6]);

  useEffect(() => {
    if (chart6Loading) {
      setData([]);
      setHighestSalary(null);
      setLowestSalary(null);
      setAverageSalary(null);
    }
  }, [chart6Loading]);

  const series = [
    {
      name: "Average Salary (LPA)",
      data: data.map((item) => item.salary),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "area", // Changed from "line" to "area"
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: false,
        top: 10,
        left: 5,
        blur: 3,
        color: primaryColor,
        opacity: 0.15,
      },
    },
    stroke: {
      width: 3,
      curve: "straight", // Curve adjusted for area chart
      fill: {
        type: "solid",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
    },
    fill: {
      type: "gradient", // Added fill settings for area chart
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels on data points
    },
    colors: [brandColors[3]],
    grid: {
      borderColor: chartBorderColor,
      strokeDashArray: 3,
      padding: {
        top: -10,
        bottom: 8,
        left: 30,
        right: 100,
      },
    },
    xaxis: {
      categories: data.map((item) => item.experience),
      title: {
        text: "Experience (years)",
      },
    },
    yaxis: {
      title: {
        text: "Average Salary (LPA)",
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) => `${value} LPA`,
      },
    },
    markers: {
      size: 4,
      colors: brandColors[2],
    },
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: primaryColor,
        fontSize: "14px",
      },
    },
  };

  return (
    <div>
      <p className="chart-heading">
        {title} average salary across experience levels in {location}
      </p>
      {data.length > 0 ? (
        <div>
          <div className="d-flex justify-content-center">
            <Chart
              options={options}
              series={series}
              type="area"
              height={400}
              width={650}
            />
          </div>
          <p className="chart-summary mb-4">
            The chart displays the average salary across different experience
            levels for the role of <span className="text-primary">{title}</span>{" "}
            in <span className="text-primary">{location}</span>. The{" "}
            <span className="text-primary">highest average salary</span>{" "}
            observed is {highestSalary} LPA, while the{" "}
            <span className="text-primary">lowest average salary</span> is{" "}
            {lowestSalary} LPA. The{" "}
            <span className="text-primary">average salary</span> across all
            experience levels is {averageSalary} LPA.
          </p>
        </div>
      ) : (
        <div className="graph-nodata-div">
          {chart6Loading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AverageSalaryExperienceChart;
