import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Divider, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_ROLE_ERROR,
  FETCH_ROLE_LOADING,
  FETCH_ROLE_SUCCESS,
} from "../../Store/Types/SalaryDashBoardReducerTypes";
import { api_request } from "../../Store/Action/CommonAction";
import { method_get } from "../../Config/config";
import { primaryColor } from "../../Config/constants";
import { AppDispatch } from "../../Store/Types/CommonTypes";
import StaticReportInput from "./StaticReportInput";
import StaticReportOutput from "./StaticReportOutput";

const StaticReportTool = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(true); // Initial loading state
  //eslint-disable-next-line
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [progress, setProgress] = useState(0); // Progress bar value

  const accessToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OCwiaWF0IjoxNzIzMDM4NDM1LCJleHAiOjE3MjMwNjAwMzV9.58-GOoOzEEpJzprNvpjiuHw6qCXJRGEp-odfEHxyieo";
  const industriesLoading = useSelector(
    (state: any) => state.salaryDashboard.industryLoading
  );

  useEffect(() => {
    // Fetch data on component mount

    dispatch(
      api_request(
        method_get,
        "/api/pay-pulse/roles",
        null,
        FETCH_ROLE_LOADING,
        FETCH_ROLE_SUCCESS,
        FETCH_ROLE_ERROR,
        accessToken
      )
    );
  }, [accessToken, dispatch]);
  useEffect(() => {
    // Check if all loading variables are false
    if (industriesLoading) {
      setLoading(true); // Update loading state when all are false
      // // Calculate progress based on loading variables
      const totalLoadingVariables = 2; // Total number of loading variables
      const trueCount = 1 + (industriesLoading ? 0 : 1);
      setProgress((trueCount / totalLoadingVariables) * 100); // Update progress
    } else {
      setProgress(100);
      setLoading(false); // Update loading state when all are false
    }
  }, [industriesLoading]);
  return (
    <>
      <Helmet>
        <title>Paytrend</title>
      </Helmet>
      {loading ? (
        <div className="preloader-container container">
          <h1>Paytrend</h1>
          <Progress
            className="preloader-progress"
            percent={progress}
            showInfo={false}
            status="active"
            strokeColor={{
              "0%": primaryColor,
              "100%": primaryColor,
            }}
          />
        </div>
      ) : (
        <div className="">
          <div>
            <div className="col-lg-12 input-container-static  d-lg-block">
              <div className="logo">
                <h2
                  className="my-2 text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    (window.location.href = "https://www.humello.com/paytrend")
                  }
                >
                  Paytrend
                </h2>
              </div>
              <Divider className="m-0" />
              <div className="d-lg-flex justify-content-center">
                <div className="col-lg-6">
                  <StaticReportInput setShowMobileMenu={setShowMobileMenu} />
                </div>
              </div>
            </div>
          </div>
          <div className="output-container-static">
            <div className="col-lg-9  scrollable-container p-0  pt-5">
              <StaticReportOutput />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StaticReportTool;
