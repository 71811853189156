import { combineReducers } from "redux";
import salaryDashBoardReducer from "./SalaryDashBoardReducer";
import authReducer from "./AuthReducer";
import commonReducer from "./CommonReducer";

const rootReducer = combineReducers({
  salaryDashboard: salaryDashBoardReducer,
  auth: authReducer,
  common: commonReducer,
});

export default rootReducer;
