import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { primaryColor } from "../../Config/constants";
import { useApplicationContext } from "../../Context/app-context";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const MostCommonSkillsChart = () => {
  const { isMobile } = useApplicationContext();
  const [data, setData] = useState([]);

  const chart4 = useSelector((state) => state.salaryDashboard.chart4);

  const chart4Loading = useSelector(
    (state) => state.salaryDashboard.chart4loading
  );

  useEffect(() => {
    if (chart4) {
      // Convert chart4 data into desired format with skill names capitalized
      const formattedData = chart4.map((item) => ({
        ...item,
        skill_name:
          item.skill_name.charAt(0).toUpperCase() + item.skill_name.slice(1),
      }));
      setData(formattedData);
    }
  }, [chart4]);

  useEffect(() => {
    if (chart4Loading) {
      setData([]);
    }
  }, [chart4Loading]);

  // Options for ApexCharts
  const options = {
    chart: {
      type: "donut",
      dropShadow: {
        enabled: false,
      },
      offsetY: -3,
    },
    labels: data.map((data) => data.skill_name),

    colors: [primaryColor, "#007bff", "#f8d90f", "#d3dd18", "#fe7a15"], // Customize colors as needed
    legend: {
      show: true,
      position: "bottom",
      fontSize: isMobile ? "10px" : "",
      offsetY: 10,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 40, // Adjust the position of the data labels
          minAngleToShowLabel: 10, // Minimum angle to show data label (optional)
        },
        offsetY: 20,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
      style: {
        colors: ["#000"], // Set data label color to black
        fontWeight: "normal",
      },
      dropShadow: {
        enabled: false, // Remove shadow effect
      },
    },
  };

  // Series data for ApexCharts
  const series = data.map((data) => data.frequency);

  return (
    <div>
      <p className="chart-heading mb-4"> Most Common Skills</p>

      {data.length > 0 ? (
        <Chart
          options={options}
          series={series}
          type="donut"
          height={isMobile ? 250 : 300}
        />
      ) : (
        <div className="graph-nodata-div">
          {chart4Loading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MostCommonSkillsChart;
