import BlogList from "./blogList";
import blogContent from "../../Config/blogContent.json";
import { useNavigate } from "react-router-dom";
import LogoComponent from "../LogoComponent";

const BlogsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" border-bottom py-3">
        <div className=" d-flex justify-content-between container">
          <h1
            className="m-0 text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Paytrend
          </h1>
          <LogoComponent />
        </div>
      </div>
      <div className="hero-container hero-tool">
        <div className="container">
          <div className="row">
            <div className="hero-caption col-lg-12 ">
              <div className="text-center ">
                <h1 data-animation="fadeInLeft" className="" data-delay=".4s">
                  Paytrend Blogs
                </h1>
                <p data-animation="fadeInLeft" data-delay=".6s">
                  Stay informed with the latest insights, trends, and updates on
                  salary benchmarking and real-time compensation data. Explore
                  our expert articles to help you make data-driven decisions in
                  today’s dynamic job market
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <section className="py-5">
          <BlogList blogs={blogContent} limit={100} />
        </section>
      </div>
    </>
  );
};

export default BlogsPage;
