import React from "react";
import humello from "../Images/humello.png";
import { useNavigate } from "react-router-dom";

const LogoComponent = () => {
  const navigate = useNavigate();
  return (
    <div>
      <a href="https://humello.com/" className="d-flex align-items-center">
        <img src={humello} alt="Humello" width={130} />
      </a>
    </div>
  );
};

export default LogoComponent;
