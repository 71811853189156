import { Bar } from "@ant-design/charts";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";
import { brandColors } from "../../Config/constants";

interface DataType {
  skill: string;
  salary: number;
}

const AverageSalarySkillsChart = ({
  dataSet,
  dataSetLoading,
  title,
  location,
  experience,
}: {
  dataSet: any;
  dataSetLoading: boolean;
  title: string;
  location: string;
  experience: number;
}) => {
  const [data, setData] = useState<DataType[]>([]);

  useEffect(() => {
    if (dataSet) {
      const newData = dataSet.map((item: DataType) => ({
        ...item,
        skill: item.skill,
        salary: convertToLakhsPerAnnum(item.salary),
      }));
      setData(newData);
    }
  }, [dataSet]);

  useEffect(() => {
    if (dataSetLoading) {
      setData([]);
    }
  }, [dataSetLoading]);

  // Find the skill with the highest and lowest average salary
  const highestSkill = data.reduce(
    (prev, current) => (prev.salary > current.salary ? prev : current),
    data[0]
  );

  const lowestSkill = data.reduce(
    (prev, current) => (prev.salary < current.salary ? prev : current),
    data[0]
  );

  // Find the skill with the median salary
  const sortedData = [...data].sort((a, b) => a.salary - b.salary);
  const middleIndex = Math.floor(sortedData.length / 2);
  const middleSkill =
    sortedData.length % 2 !== 0 ? sortedData[middleIndex] : null;

  const chartConfig = {
    data,
    xField: "skill",
    yField: "salary",
    colorField: "skill",
    paddingRight: 28,
    style: {
      lineWidth: 1,
      fill: (d: any) => {
        // Determine the index of the color from the palette
        const index =
          data.findIndex((item) => item.skill === d.skill) % brandColors.length;
        return brandColors[index];
      },
      stroke: (d: any) => {
        // Determine the index of the color from the palette
        const index =
          data.findIndex((item) => item.skill === d.skill) % brandColors.length;
        return brandColors[index];
      },
      fillOpacity: 0.8,
      StrokeOpacity: 0.8,
    },
    barStyle: {
      radius: [10, 10, 0, 0],
    },
    label: {
      style: {
        textAlign: (d: any) => (+d.frequency > 0.008 ? "outside" : "outside"),
        fill: (d: any) => (+d.frequency > 0.008 ? "#fff" : "#000"),
        dx: (d: any) => (+d.frequency > 0.008 ? -5 : 5),
      },
      formatter: (value: any) => `${value} LPA`,
    },
    xAxis: {
      title: {
        text: "Skill",
      },
      label: {
        style: {
          fill: "#000",
        },
      },
    },
    yAxis: {
      title: {
        text: "Average Salary (LPA)",
      },
      label: {
        style: {
          fill: "#000",
          fontSize: 12,
        },
      },
    },
    axis: {
      x: {
        title: "Skill",
        titleFill: "#000000",
        titleFontWeight: "bold",
        labelFill: "#000000",
        labelFillOpacity: 1,

        labelStroke: "#000000",
        labelStrokeOpacity: 1,
        labelFontWeight: 100,
      },
      y: {
        title: "Average Salary (LPA)",
        titleFill: "#000000",
        titleFontWeight: "bold",
        labelFill: "#000000",
        labelFillOpacity: 1,
        labelStroke: "#000000",
        labelStrokeOpacity: 1,
        labelFontWeight: 100,
        tickCount: 5,
        label: {
          style: {
            fill: "#000", // Ensure y-axis labels are black
            fontSize: 5,
            fontFamily: "Arial, Helvetica, sans-serif",
          },
        },
      },
    },
    tooltip: {
      formatter: (datum: any) => ({
        name: "Average Salary",
        value: `${datum.salary?.toLocaleString()} LPA`,
      }),
    },
    legend: false,
    interactions: [{ type: "element-active" }],
  };

  return (
    <div>
      <p className="chart-heading">
        {title} average salary for most common skills
      </p>

      {data.length > 0 ? (
        <div>
          <Bar {...chartConfig} height={380} width={750} />
          <p className="chart-summary mt-4">
            For the role of <span className="text-primary">{title}</span> in{" "}
            <span className="text-primary">{location}</span>, requiring{" "}
            <span className="text-primary">
              {experience} {experience === 1 ? "year" : "years"}
            </span>{" "}
            of experience, the skill with the highest average salary is
            <span className="text-primary">
              {" "}
              {highestSkill.skill} ({highestSkill.salary} LPA)
            </span>
            . Conversely, the skill with the lowest average salary is
            <span className="text-primary">
              {" "}
              {lowestSkill.skill} ({lowestSkill.salary} LPA)
            </span>
            . Additionally, the skill with a median salary is
            {middleSkill && (
              <span className="text-primary">
                {" "}
                {middleSkill.skill} ({middleSkill.salary} LPA)
              </span>
            )}
            , providing a balanced perspective on salary expectations for this
            role.
          </p>
        </div>
      ) : (
        <div className="graph-nodata-div">
          {dataSetLoading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AverageSalarySkillsChart;
