import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { brandColors } from "../../Config/constants";
import { useApplicationContext } from "../../Context/app-context";
import { LoadingOutlined } from "@ant-design/icons";

const MostCommonSkillsChart = ({
  dataSet,
  dataSetLoading,
  title,
  location,
  experience,
}) => {
  const { isMobile } = useApplicationContext();
  const [data, setData] = useState([]);

  const chart4 = dataSet;
  const chart4Loading = dataSetLoading;

  useEffect(() => {
    if (chart4) {
      // Convert chart4 data into desired format with skill names capitalized
      const formattedData = chart4.map((item) => ({
        ...item,
        skill_name: item.skill_name,
      }));
      setData(formattedData);
    }
  }, [chart4]);

  useEffect(() => {
    if (chart4Loading) {
      setData([]);
    }
  }, [chart4Loading]);

  // Options for ApexCharts
  const options = {
    chart: {
      type: "donut",
      dropShadow: {
        enabled: false,
      },
      offsetY: -3,
    },
    labels: data.map((data) => data.skill_name),

    colors: brandColors, // Customize colors as needed
    legend: {
      show: true,
      position: "bottom",
      fontSize: isMobile ? "10px" : "",
      offsetX: -100,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 40, // Adjust the position of the data labels
          minAngleToShowLabel: 10, // Minimum angle to show data label (optional)
        },
        offsetY: 15,
        offsetX: -60,
        // donut: {
        //   size: "60%",
        // },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
      style: {
        colors: ["#000"], // Set data label color to black
        fontWeight: "normal",
      },
      dropShadow: {
        enabled: false, // Remove shadow effect
      },
    },
  };

  // Series data for ApexCharts
  const series = data.map((data) => data.frequency);

  // Determine the most common skill and its frequency percentage
  const mostCommonSkill = data.length > 0 ? data[0].skill_name : "";
  const mostCommonSkillFrequency = data.length > 0 ? series[0] : 0;
  const totalFrequency = series.reduce((acc, curr) => acc + curr, 0);
  const mostCommonSkillPercentage = (
    (mostCommonSkillFrequency / totalFrequency) *
    100
  ).toFixed(2);

  return (
    <div>
      <p className="chart-heading mb-4">{title} most common skills</p>

      {data.length > 0 ? (
        <Chart
          options={options}
          series={series}
          type="donut"
          height={isMobile ? 250 : 300}
        />
      ) : (
        <div className="graph-nodata-div">
          {chart4Loading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}

      <p className="chart-summary mt-4">
        The chart shows the most common skills for the role of{" "}
        <span className="text-primary">{title}</span> in{" "}
        <span className="text-primary">{location}</span> with{" "}
        <span className="text-primary">
          {experience} {experience === 1 ? "year" : "years"} of experience
        </span>
        . The most common skill is{" "}
        <span className="text-primary">{mostCommonSkill}</span>, accounting for{" "}
        <span className="text-primary">{mostCommonSkillPercentage}%</span> of
        the top 5 skills.
      </p>
    </div>
  );
};

export default MostCommonSkillsChart;
