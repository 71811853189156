export const convertToLakhsPerAnnum = (salaryInRupees: number): number => {
  if (salaryInRupees) {
    const lakhsPerAnnum = salaryInRupees / 100000;
    return parseFloat(lakhsPerAnnum.toFixed(1)); // Round to two decimal points
  } else {
    return 0;
  }
};

export const capitalizeWord= (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}