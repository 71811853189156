import axios, { AxiosRequestConfig } from "axios";
import { AnyAction, Dispatch } from "redux";
import { backendUrl, method_get } from "../../Config/config";
import { FORBIDDEN_ACTION, UNAUTHORIZED_USER } from "../Types/AuthTypes";
import { SET_COMMON_REDUCER } from "../Types/CommonTypes";

export const api_request = (
  method: string,
  path: string,
  body: any,
  loadType: string,
  successType: string,
  errorType: string,
  accessToken: string | null
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: loadType });

    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    if (method !== method_get) {
      headers["Content-Type"] = "application/json";
    }

    if (accessToken) {
      headers["token"] = "Bearer " + accessToken;
    }

    const config: AxiosRequestConfig = {
      method: method,
      url: backendUrl + path,
      headers: headers,
      data: method === method_get ? undefined : body,
    };

    try {
      const response = (await axios(config)).data;

      if (response.status === 200) {
        dispatch({
          type: successType,
          payload: response.data,
          message: response.message,
          sql: response.sql,
          skill: response.skill,
        });
      } else if (response.status === 401) {
        dispatch({ type: UNAUTHORIZED_USER, message: response.message });
      } else if (response.status === 403) {
        dispatch({ type: FORBIDDEN_ACTION, message: response.message });
      } else {

        dispatch({ type: errorType, message: response.message });

      }
    } catch (error) {
      console.error(error);
      dispatch({ type: errorType });
    }
  };
};

export const setCommon = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      payload: data,
      type: SET_COMMON_REDUCER,
    });
  };
};
