import { Scatter } from "@ant-design/plots";
import React, { useEffect, useState } from "react";
import { regressionLinear } from "d3-regression";
import { useSelector } from "react-redux";
import { brandColors, primaryColor } from "../../Config/constants";
import { LoadingOutlined } from "@ant-design/icons";

const DemoScatter = () => {
  const [data, setData] = useState([]);
  const chart7 = useSelector((state) => state.salaryDashboard.chart7);
  const chart7Loading = useSelector(
    (state) => state.salaryDashboard.chart7loading
  );

  useEffect(() => {
    if (chart7) {
      // Convert salary values to lakhs per annum
      const newData = chart7.map((item) => ({
        ...item,
        salary: item.salary / 100000, // Convert salary to lakhs
      }));
      setData(newData);
    }
  }, [chart7]);

  useEffect(() => {
    if (chart7Loading) {
      setData([]);
    }
  }, [chart7Loading]);

  const lineData = regressionLinear()
    .x((d) => d.experience)
    .y((d) => d.salary)(data);

  const config = {
    data,
    xField: "experience",
    yField: "salary",
    axis: {
      x: {
        title: "Experience (year)",
        titleFill: "#000000",
        titleFontWeight: "bold",
        labelFill: "#000000",
        labelFillOpacity: 1,

        labelStroke: "#3d3d3d",
        labelStrokeOpacity: 1,
        labelFontWeight: 5,
      },
      y: {
        title: "Salary (LPA)",
        titleFill: "#000000",
        titleFontWeight: "bold",
        labelFill: "#000000",
        labelFillOpacity: 1,

        labelStroke: "#3d3d3d",
        labelStrokeOpacity: 1,
        labelFontWeight: 5,
        labelSpacing: 15,
      },
    },

    sizeField: 5,
    style: {
      stroke: "#878787",
      lineWidth: 1,
      fill: brandColors[3],
    },
    xAxis: {
      tickCount: data.length, // Set tickCount to the length of your data array
    },

    line: {
      data: lineData,
      xField: (d) => d[0],
      yField: (d) => d[1],
      style: { stroke: brandColors[0], lineWidth: 2 },
      tooltip: false,
    },
    scale: {
      y: {
        nice: true,

        domainMin: 0,
      },
    },
  };
  return (
    <div className="scatter-container ">
      <p className="chart-heading"> Salary Across Experience Levels</p>{" "}
      {data.length > 0 ? (
        <div>
          <Scatter {...config} height={300} />
        </div>
      ) : (
        <div className="graph-nodata-div">
          {chart7Loading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DemoScatter;
