import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

export type AppDispatch = ThunkDispatch<{}, {}, AnyAction>;
export type ActionTypeCommon = {
  type: typeof SET_COMMON_REDUCER;
  payload: any;
};

export const SET_COMMON_REDUCER = "SET_COMMON_REDUCER";
