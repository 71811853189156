import { Reducer } from "redux";
import {
  ActionType,
  CHART1_ERROR,
  CHART1_LOADING,
  CHART1_SUCCESS,
  CHART2_ERROR,
  CHART2_LOADING,
  CHART2_SUCCESS,
  CHART3_ERROR,
  CHART3_LOADING,
  CHART3_SUCCESS,
  CHART4_ERROR,
  CHART4_LOADING,
  CHART4_SUCCESS,
  FETCH_DEPARTMENT_ERROR,
  FETCH_DEPARTMENT_LOADING,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_ROLE_CATEGORY_ERROR,
  FETCH_ROLE_CATEGORY_LOADING,
  FETCH_ROLE_CATEGORY_SUCCESS,
  FETCH_ROLE_ERROR,
  FETCH_ROLE_LOADING,
  FETCH_ROLE_SUCCESS,
  SET_SALARY_DASHBOARD,
  FETCH_LOCATION_ERROR,
  FETCH_LOCATION_LOADING,
  FETCH_LOCATION_SUCCESS,
  FETCH_INDUSTRY_ERROR,
  FETCH_INDUSTRY_LOADING,
  FETCH_INDUSTRY_SUCCESS,
  FETCH_SKILL_LOADING,
  FETCH_SKILL_SUCCESS,
  FETCH_SKILL_ERROR,
  CHART5_LOADING,
  CHART5_SUCCESS,
  CHART5_ERROR,
  CHART6_LOADING,
  CHART6_SUCCESS,
  CHART6_ERROR,
  FETCH_VALID_INPUT_LOADING,
  FETCH_VALID_INPUT_SUCCESS,
  FETCH_VALID_INPUT_ERROR,
  CHART7_ERROR,
  CHART7_SUCCESS,
  CHART7_LOADING,
  AGGREGATED_LOADING,
  AGGREGATED_SUCCESS,
  AGGREGATED_ERROR,
} from "../Types/SalaryDashBoardReducerTypes";

const initState = {
  sql1: null,
  chart1: null,
  skill1: null,
  chart1loading: false,
  chart1success: false,
  chart1error: false,

  sql2: null,
  chart2: null,
  skill2: null,
  chart2loading: false,
  chart2success: false,
  chart2error: false,

  sql3: null,
  chart3: null,
  skill3: null,
  chart3loading: false,
  chart3success: false,
  chart3error: false,

  sql4: null,
  chart4: null,
  skill4: null,
  chart4loading: false,
  chart4success: false,
  chart4error: false,

  sql5: null,
  chart5: null,
  skill5: null,
  chart5loading: false,
  chart5success: false,
  chart5error: false,

  sql6: null,
  chart6: null,
  skill6: null,
  chart6loading: false,
  chart6success: false,
  chart6error: false,

  sql7: null,
  chart7: null,
  skill7: null,
  chart7loading: false,
  chart7success: false,
  chart7error: false,

  // Role state
  roleLoading: false,
  roleSuccess: false,
  roleError: false,
  role: null,

  // Department state
  departmentLoading: false,
  departmentSuccess: false,
  departmentError: false,
  department: null,

  // Role category state
  roleCategoryLoading: false,
  roleCategorySuccess: false,
  roleCategoryError: false,
  roleCategory: null,

  // Location state
  locationLoading: false,
  locationSuccess: false,
  locationError: false,
  location: null,

  // Industry state
  industryLoading: false,
  industrySuccess: false,
  industryError: false,
  industry: null,

  // Industry state
  skillLoading: false,
  skillSuccess: false,
  skillError: false,
  skill: null,

  validInputLoading: false,
  validInputSuccess: false,
  validInputError: false,
  validInput: null,
};

const salaryDashBoardReducer: Reducer<typeof initState, ActionType> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case CHART1_LOADING:
      return {
        ...state,
        sql1: null,
        chart1: null,
        chart1loading: true,
        chart1success: false,
        chart1error: false,
      };
    case CHART1_SUCCESS:
      return {
        ...state,
        sql1: action.sql,
        chart1: action.payload,
        skill1: action.skill,
        chart1loading: false,
        chart1success: true,
        chart1error: false,
      };
    case CHART1_ERROR:
      return {
        ...state,
        sql1: null,
        chart1: null,
        chart1loading: false,
        chart1success: false,
        chart1error: true,
      };
    case CHART2_LOADING:
      return {
        ...state,
        sql2: null,
        chart2: null,
        chart2loading: true,
        chart2success: false,
        chart2error: false,
      };
    case CHART2_SUCCESS:
      return {
        ...state,
        sql2: action.sql,
        chart2: action.payload,
        skill2: action.skill,
        chart2loading: false,
        chart2success: true,
        chart2error: false,
      };
    case CHART2_ERROR:
      return {
        ...state,
        sql2: null,
        chart2: null,
        chart2loading: false,
        chart2success: false,
        chart2error: true,
      };
    case CHART3_LOADING:
      return {
        ...state,
        sql3: null,
        chart3: null,
        chart3loading: true,
        chart3success: false,
        chart3error: false,
      };
    case CHART3_SUCCESS:
      return {
        ...state,
        sql3: action.sql,
        chart3: action.payload,
        skill3: action.skill,
        chart3loading: false,
        chart3success: true,
        chart3error: false,
      };
    case CHART3_ERROR:
      return {
        ...state,
        sql3: null,
        chart3: null,
        chart3loading: false,
        chart3success: false,
        chart3error: true,
      };
    case CHART4_LOADING:
      return {
        ...state,
        sql4: null,
        chart4: null,
        chart4loading: true,
        chart4success: false,
        chart4error: false,
      };
    case CHART4_SUCCESS:
      return {
        ...state,
        sql4: action.sql,
        chart4: action.payload,
        skill4: action.skill,
        chart4loading: false,
        chart4success: true,
        chart4error: false,
      };
    case CHART4_ERROR:
      return {
        ...state,
        sql4: null,
        chart4: null,
        chart4loading: false,
        chart4success: false,
        chart4error: true,
      };
    case CHART5_LOADING:
      return {
        ...state,
        sql5: null,
        chart5: null,
        chart5loading: true,
        chart5success: false,
        chart5error: false,
      };
    case CHART5_SUCCESS:
      return {
        ...state,
        sql5: action.sql,
        chart5: action.payload,
        skill5: action.skill,
        chart5loading: false,
        chart5success: true,
        chart5error: false,
      };
    case CHART5_ERROR:
      return {
        ...state,
        sql5: null,
        chart5: null,
        chart5loading: false,
        chart5success: false,
        chart5error: true,
      };
    case CHART6_LOADING:
      return {
        ...state,
        sql6: null,
        chart6: null,
        chart6loading: true,
        chart6success: false,
        chart6error: false,
      };
    case CHART6_SUCCESS:
      return {
        ...state,
        sql6: action.sql,
        chart6: action.payload,
        skill6: action.skill,
        chart6loading: false,
        chart6success: true,
        chart6error: false,
      };
    case CHART6_ERROR:
      return {
        ...state,
        sql6: null,
        chart6: null,
        chart6loading: false,
        chart6success: false,
        chart6error: true,
      };
    case CHART7_LOADING:
      return {
        ...state,
        sql7: null,
        chart7: null,
        chart7loading: true,
        chart7success: false,
        chart7error: false,
      };
    case CHART7_SUCCESS:
      return {
        ...state,
        sql7: action.sql,
        chart7: action.payload,
        skill7: action.skill,
        chart7loading: false,
        chart7success: true,
        chart7error: false,
      };
    case CHART7_ERROR:
      return {
        ...state,
        sql7: null,
        chart7: null,
        chart7loading: false,
        chart7success: false,
        chart7error: true,
      };
      
      case AGGREGATED_LOADING:
      return {
        ...state,
        aggregated: null,
        aggregatedloading: true,
        aggregatedsuccess: false,
        aggregatederror: false,
      };
    case AGGREGATED_SUCCESS:
      return {
        ...state,
        aggregated: action.payload,
        aggregatedloading: false,
        aggregatedsuccess: true,
        aggregatederror: false,
      };
    case AGGREGATED_ERROR:
      return {
        ...state,
       aggregated: null,
       aggregatedloading: false,
       aggregatedsuccess: false,
       aggregatederror: true,
      };
    case FETCH_ROLE_LOADING:
      return {
        ...state,
        roleLoading: true,
        roleSuccess: false,
        roleError: false,
      };
    case FETCH_ROLE_SUCCESS:
      return {
        ...state,
        roleLoading: false,
        roleSuccess: true,
        roleError: false,
        role: action.payload,
      };
    case FETCH_ROLE_ERROR:
      return {
        ...state,
        roleLoading: false,
        roleSuccess: false,
        roleError: true,
        role: null,
      };

    // Department cases
    case FETCH_DEPARTMENT_LOADING:
      return {
        ...state,
        departmentLoading: true,
        departmentSuccess: false,
        departmentError: false,
      };
    case FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departmentLoading: false,
        departmentSuccess: true,
        departmentError: false,
        department: action.payload,
      };
    case FETCH_DEPARTMENT_ERROR:
      return {
        ...state,
        departmentLoading: false,
        departmentSuccess: false,
        departmentError: true,
        department: null,
      };

    // Role category cases
    case FETCH_ROLE_CATEGORY_LOADING:
      return {
        ...state,
        roleCategoryLoading: true,
        roleCategorySuccess: false,
        roleCategoryError: false,
      };
    case FETCH_ROLE_CATEGORY_SUCCESS:
      return {
        ...state,
        roleCategoryLoading: false,
        roleCategorySuccess: true,
        roleCategoryError: false,
        roleCategory: action.payload,
      };
    case FETCH_ROLE_CATEGORY_ERROR:
      return {
        ...state,
        roleCategoryLoading: false,
        roleCategorySuccess: false,
        roleCategoryError: true,
        roleCategory: null,
      };

    // Location cases
    case FETCH_LOCATION_LOADING:
      return {
        ...state,
        locationLoading: true,
        locationSuccess: false,
        locationError: false,
      };
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        locationLoading: false,
        locationSuccess: true,
        locationError: false,
        location: action.payload,
      };
    case FETCH_LOCATION_ERROR:
      return {
        ...state,
        locationLoading: false,
        locationSuccess: false,
        locationError: true,
        location: null,
      };

    // Industry cases
    case FETCH_INDUSTRY_LOADING:
      return {
        ...state,
        industryLoading: true,
        industrySuccess: false,
        industryError: false,
      };
    case FETCH_INDUSTRY_SUCCESS:
      return {
        ...state,
        industryLoading: false,
        industrySuccess: true,
        industryError: false,
        industry: action.payload,
      };
    case FETCH_INDUSTRY_ERROR:
      return {
        ...state,
        industryLoading: false,
        industrySuccess: false,
        industryError: true,
        industry: null,
      };

    // Skill cases
    case FETCH_SKILL_LOADING:
      return {
        ...state,
        skillLoading: true,
        skillSuccess: false,
        skillError: false,
        skill: null,
      };
    case FETCH_SKILL_SUCCESS:
      return {
        ...state,
        skillLoading: false,
        skillSuccess: true,
        skillError: false,
        skill: action.payload,
      };
    case FETCH_SKILL_ERROR:
      return {
        ...state,
        skillLoading: false,
        skillSuccess: false,
        skillError: true,
        skill: null,
      };
    // Skill cases
    case FETCH_VALID_INPUT_LOADING:
      return {
        ...state,
        validInputLoading: true,
        validInputSuccess: false,
        validInputError: false,
        validInput: null,
      };
    case FETCH_VALID_INPUT_SUCCESS:
      return {
        ...state,
        validInputLoading: false,
        validInputSuccess: true,
        validInputError: false,
        validInput: action.payload,
      };
    case FETCH_VALID_INPUT_ERROR:
      return {
        ...state,
        validInputLoading: false,
        validInputSuccess: false,
        validInputError: true,
        validInput: null,
      };
    case SET_SALARY_DASHBOARD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default salaryDashBoardReducer;
