import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  brandColors,
  chartBorderColor,
  primaryColor,
} from "../../Config/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";

interface DataType {
  period: string;
  salary: number;
}

const AverageSalaryChart = ({
  dataSet,
  dataSetLoading,
  title,
  location,
  experience,
}: {
  dataSet: any;
  dataSetLoading: boolean;
  title: string;
  location: string;
  experience: number;
}) => {
  const [data, setData] = useState<DataType[]>([]);
  const [percentageChange, setPercentageChange] = useState<number | null>(null);
  const chart3 = dataSet;

  const chart3Loading = dataSetLoading;

  useEffect(() => {
    if (chart3) {
      // Convert chart3 data into desired format
      const medianSalaryData = chart3.map((item: any) => {
        return {
          period: `Last ${item.days}`,
          salary: convertToLakhsPerAnnum(item.median),
        };
      });

      medianSalaryData?.reverse();

      if (medianSalaryData.length > 0 && medianSalaryData[1].salary === 0) {
        setData([]);
      } else if (
        medianSalaryData.length > 0 &&
        medianSalaryData[2].salary === 0
      ) {
        const filteredData = medianSalaryData.slice(0, -1);
        setData(filteredData);
      } else {
        setData(medianSalaryData);
      }

      // Calculate percentage change
      if (medianSalaryData.length > 1) {
        const initialSalary = medianSalaryData[0].salary;
        const finalSalary =
          medianSalaryData[medianSalaryData.length - 1].salary;
        const change = ((finalSalary - initialSalary) / initialSalary) * 100;
        setPercentageChange(change);
      } else {
        setPercentageChange(null);
      }
    }
  }, [chart3]);

  // Calculate min and max salary values
  const maxSalary = Math.max(...data?.map((data) => data.salary));

  useEffect(() => {
    if (chart3Loading) {
      setData([]);
    }
  }, [chart3Loading]);

  const series = [
    {
      name: "Average Salary (LPA)",
      data: data.map((item) => item.salary),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "area", // Changed from "line" to "area"

      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      curve: "smooth", // Curve adjusted for area chart
      fill: {
        type: "solid",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
    },
    fill: {
      type: "gradient", // Added fill settings for area chart
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels on data points
    },
    colors: [brandColors[3]],
    grid: {
      borderColor: chartBorderColor,
      strokeDashArray: 3,
      padding: {
        top: -10,
        bottom: 8,
        left: 30,
        right: 30,
      },
    },
    xaxis: {
      categories: data.map((item) => item.period),
      title: {
        text: "Time",
      },
    },
    yaxis: {
      title: {
        text: "Average Salary (LPA)",
      },
      min: 0,
      max: maxSalary + 5, // Adjusted maximum to create some padding
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) => `${value} LPA`,
      },
    },

    markers: {
      size: 4,
      colors: brandColors[2],
    },
    // grid: {
    //   borderColor: "#e7e7e7",
    //   row: {
    //     colors: ["#f3f3f3", "transparent"], // alternating background colors for rows
    //     opacity: 0.5,
    //   },
    // },
    noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: primaryColor,
        fontSize: "14px",
      },
    },
  };

  return (
    <div>
      <p className="chart-heading">{title} average salary trend</p>

      {data.length > 0 ? (
        <div className="d-flex justify-content-center">
          <Chart
            options={options}
            series={series}
            type="area"
            height={400}
            width={750}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          {chart3Loading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}

      <p className="chart-summary mb-4">
        The chart displays the trend of average salary over time for the role of{" "}
        <span className="text-primary">{title}</span> in{" "}
        <span className="text-primary">{location}</span> with{" "}
        <span className="text-primary">{experience}</span>{" "}
        {experience === 1 ? "year" : "years"} of experience. The{" "}
        <span className="text-primary">highest average salary</span> observed is{" "}
        {maxSalary} LPA.
        {percentageChange !== null ? (
          <span>
            {percentageChange > 0
              ? " There has been an increase of "
              : " There has been a decrease of "}
            <span className="text-primary">
              {Math.abs(percentageChange).toFixed(2)}%
            </span>{" "}
            over the period.
          </span>
        ) : (
          " Not enough data to determine the trend."
        )}
      </p>
    </div>
  );
};

export default AverageSalaryChart;
