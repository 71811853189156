export const primaryColor = "#0191b4";
export const chartBorderColor = "#f2eeee";
export const brandColors = [
  "#0191b4",
  "#f8d90f",
  "#d3dd18",
  "#fe7a15",
  "#007bff",
  "#f8d90f",
];
export const profileThreshold = 2;
