import { useEffect, useState } from "react";
import { Bar } from "@ant-design/charts";
import { LoadingOutlined } from "@ant-design/icons";
import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";
import { brandColors } from "../../Config/constants";

interface IndustryData {
  industry_id: number;
  industry_name: string;
  average_salary: number;
}

const IndustrySalaryChart = ({
  dataSet,
  dataSetLoading,
  title,
  location,
}: {
  dataSet: any;
  dataSetLoading: boolean;
  title: string;
  location: string;
}) => {
  const [data, setData] = useState<IndustryData[]>([]);

  useEffect(() => {
    if (dataSet) {
      const newData = dataSet
        .map((item: IndustryData) => ({
          ...item,
          industry_name:
            item.industry_name.toLowerCase() === "miscellaneous"
              ? "Others"
              : item.industry_name.charAt(0).toUpperCase() +
                item.industry_name.slice(1),
          average_salary: convertToLakhsPerAnnum(item.average_salary),
        }))
        .slice(0, 15); // Display only the top 15 industries
      setData(newData);
    }
  }, [dataSet]);

  useEffect(() => {
    if (dataSetLoading) {
      setData([]);
    }
  }, [dataSetLoading]);

  const config = {
    data: data.map((item) => ({
      industry: item.industry_name,
      averageSalary: item.average_salary,
    })),
    xField: "industry",
    yField: "averageSalary",
    paddingRight: 28,
    colorField: "industry",
    style: {
      lineWidth: 1,
      fill: (d: any) => {
        // Determine the index of the color from the palette
        const index =
          data.findIndex((item) => item.industry_name === d.industry) %
          brandColors.length;
        return brandColors[index];
      },
      stroke: (d: any) => {
        // Determine the index of the color from the palette
        const index =
          data.findIndex((item) => item.industry_name === d.industry) %
          brandColors.length;
        return brandColors[index];
      },
      fillOpacity: 0.8,
      StrokeOpacity: 0.8,
    },
    legend: false,
    xAxis: {
      title: {
        text: "Industry",
        style: {
          fill: "#000", // Set x-axis title color to black
        },
      },
      label: {
        style: {
          fill: "#000", // Set x-axis labels color to black
        },
      },
    },
    yAxis: {
      title: {
        text: "Average Salary",
        style: {
          fill: "#000", // Ensure y-axis title color is black
        },
      },
      label: {
        style: {
          fill: "#000", // Ensure y-axis labels are black
          fontSize: 12,
          fontFamily: "Arial, Helvetica, sans-serif",
        },
      },
    },
    barStyle: {
      radius: [10, 10, 0, 0],
      // Adjust the bar width to increase spacing
    },
    label: {
      style: {
        textAlign: (d: any) => (+d.frequency > 0.008 ? "outside" : "outside"),
        fill: (d: any) => (+d.frequency > 0.008 ? "#fff" : "#000"),
        dx: (d: any) => (+d.frequency > 0.008 ? -5 : 5),
      },
      formatter: (value: any) => `${value} LPA`,
    },
    axis: {
      x: {
        title: "Industry",
        titleFill: "#000000",
        titleFontWeight: "bold",
        labelFill: "#000000",
        labelFillOpacity: 1,

        labelStroke: "#000000",
        labelStrokeOpacity: 1,
        labelFontWeight: 100,
      },
      y: {
        title: "Average Salary (LPA)",
        titleFill: "#000000",
        titleFontWeight: "bold",
        labelFill: "#000000",
        labelFillOpacity: 1,
        labelStroke: "#000000",
        labelStrokeOpacity: 1,
        labelFontWeight: 100,
        tickCount: 5,
        label: {
          style: {
            fill: "#000", // Ensure y-axis labels are black
            fontSize: 5,
            fontFamily: "Arial, Helvetica, sans-serif",
          },
        },
      },
    },
    tooltip: {
      formatter: (datum: any) => ({
        name: "Average Salary",
        value: `${datum.averageSalary?.toLocaleString()} LPA`,
      }),
    },
    interactions: [{ type: "element-active" }],
  };

  return (
    <div>
      <p className="chart-heading">{title} average salary by industry</p>

      {data.length > 0 ? (
        <div>
          <Bar {...config} height={600} width={700} />
          <p className="chart-summary mt-4">
            For the role of <span className="text-primary">{title}</span> in{" "}
            <span className="text-primary">{location}</span>, the industry with
            the highest average salary is
            <span className="text-primary">
              {" "}
              {
                data.reduce(
                  (prev, current) =>
                    prev.average_salary > current.average_salary
                      ? prev
                      : current,
                  data[0]
                ).industry_name
              }{" "}
              (
              {
                data.reduce(
                  (prev, current) =>
                    prev.average_salary > current.average_salary
                      ? prev
                      : current,
                  data[0]
                ).average_salary
              }{" "}
              LPA)
            </span>
            . Conversely, the industry with the lowest average salary is
            <span className="text-primary">
              {" "}
              {
                data.reduce(
                  (prev, current) =>
                    prev.average_salary < current.average_salary
                      ? prev
                      : current,
                  data[0]
                ).industry_name
              }{" "}
              (
              {
                data.reduce(
                  (prev, current) =>
                    prev.average_salary < current.average_salary
                      ? prev
                      : current,
                  data[0]
                ).average_salary
              }{" "}
              LPA)
            </span>
            .
          </p>
        </div>
      ) : (
        <div className="graph-nodata-div">
          {dataSetLoading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default IndustrySalaryChart;
