import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/plots";
import { useSelector } from "react-redux";
import {
  brandColors,
  primaryColor,
  profileThreshold,
} from "../../Config/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { calculateMaxSalaryIndex } from "./SalaryLinearChart";
import { convertToLakhsPerAnnum } from "../../Utils/PaytrendHelperFunctions";

interface DataType {
  salary: number;
  count: number;
}

const SalarySalaryDistributionChart = () => {
  const [data, setData] = useState<any>([]);
  const chart2: DataType[] = useSelector(
    (state: any) => state.salaryDashboard.chart2
  );
  const chart2Loading = useSelector(
    (state: any) => state.salaryDashboard.chart2loading
  );

  useEffect(() => {
    if (chart2?.length > profileThreshold) {
      const array = calculateMaxSalaryIndex(chart2);

      // Step 1: Sort the array based on salary values
      array.sort((a, b) => a.salary - b.salary);

      // Step 2: Count occurrences of each unique salary value
      const salaryCounts: { [key: number]: number } = {};
      array.forEach((item) => {
        const salary = Math.round(convertToLakhsPerAnnum(item.salary));
        salaryCounts[salary] = (salaryCounts[salary] || 0) + 1;
      });

      // Step 3: Create a new array of objects with unique salary values and their counts
      const modifiedData = Object.entries(salaryCounts).map(
        ([salary, count]) => ({
          salary: parseFloat(salary),
          count,
        })
      );
      modifiedData.sort((a, b) => a.salary - b.salary);

      const totalCount = modifiedData.reduce(
        (acc, curr) => acc + curr.count,
        0
      );
      // Calculate frequencies for each count
      const frequencies = modifiedData.map((item) => ({
        salary: Math.round(item.salary),
        frequency: item.count / totalCount, // Frequency values range from 0 to 1
      }));

      // Normalize frequencies to range from 0 to 1
      const maxFrequency = Math.max(
        ...frequencies.map((item) => item.frequency)
      );
      const normalizedFrequencies = frequencies.map((item) => ({
        salary: item.salary,
        frequency: parseFloat((item.frequency / maxFrequency).toFixed(0)), // Normalize to range from 0 to 1
      }));

      // Step 4: Check for specific patterns and update frequencies
      for (let i = 0; i < normalizedFrequencies.length; i++) {
        if (normalizedFrequencies[i].frequency === 0) {
          if (
            (i > 0 &&
              i < normalizedFrequencies.length - 1 &&
              normalizedFrequencies[i - 1].frequency === 1 &&
              normalizedFrequencies[i + 1].frequency === 1) ||
            (i > 0 &&
              i < normalizedFrequencies.length - 2 &&
              normalizedFrequencies[i - 1].frequency === 1 &&
              normalizedFrequencies[i + 2].frequency === 1) ||
            (i > 1 &&
              i < normalizedFrequencies.length - 1 &&
              normalizedFrequencies[i - 2].frequency === 1 &&
              normalizedFrequencies[i - 1].frequency === 0 &&
              normalizedFrequencies[i + 1].frequency === 1) ||
            (i > 0 &&
              i < normalizedFrequencies.length - 3 &&
              normalizedFrequencies[i - 1].frequency === 1 &&
              normalizedFrequencies[i + 2].frequency === 1 &&
              normalizedFrequencies[i + 3].frequency === 1) ||
            (i > 0 &&
              i < normalizedFrequencies.length - 3 &&
              normalizedFrequencies[i - 1].frequency === 1 &&
              normalizedFrequencies[i + 1].frequency === 0 &&
              normalizedFrequencies[i + 3].frequency === 1)
          ) {
            normalizedFrequencies[i].frequency = 1;
          }
        } else if (normalizedFrequencies[i].frequency === 1) {
          if (
            (i > 0 &&
              i < normalizedFrequencies.length - 1 &&
              normalizedFrequencies[i - 1].frequency === 0 &&
              normalizedFrequencies[i + 1].frequency === 0) ||
            (i > 0 &&
              i < normalizedFrequencies.length - 2 &&
              normalizedFrequencies[i - 1].frequency === 0 &&
              normalizedFrequencies[i + 1].frequency === 1 &&
              normalizedFrequencies[i + 2].frequency === 0)
          ) {
            normalizedFrequencies[i].frequency = 0;
          }
        }
      }

      setData(normalizedFrequencies);
    } else {
      setData([]);
    }
  }, [chart2]);

  const config = {
    data,
    xField: "salary",
    yField: "frequency",
    shapeField: "smooth",
    smooth: true,
    curve: "smooth",
    axis: {
      x: {
        title: "  Salary (LPA)",
        titleFill: "#000000",
        titleFontWeight: "bold",
        labelFill: "#000000",
        labelFillOpacity: 1,

        labelStroke: "#3d3d3d",
        labelStrokeOpacity: 1,
        labelFontWeight: 5,

        labelSpacing: 1,
      },
      y: {
        title: "Frequency",
        titleFill: "#000000",
        titleFontWeight: "bold",
        labelFill: "#000000",
        labelFillOpacity: 1,

        labelStroke: "#3d3d3d",
        labelStrokeOpacity: 1,
        labelFontWeight: 5,
      },
    },

    tooltip: {
      title: "Salary (LPA)",
      items: [
        { channel: "y", name: "Frequency", color: primaryColor },
        {
          channel: "x",
          name: "Salary (LPA  )",
          color: brandColors[3],
        },
      ],
    },

    interaction: {
      tooltip: {
        marker: false,
      },
    },

    style: {
      lineWidth: 4,
      stroke: brandColors[3],
      // fill: brandColors[3],
    },

    scale: {
      y: {
        nice: true,
        tickCount: 1, // Display only two ticks on the y-axis
        domainMin: 0,
      },
      x: {
        tickCount: data.length <= 5 ? data.length - 1 : undefined,
      },
    },
  };

  useEffect(() => {
    if (chart2Loading) {
      setData([]);
    }
  }, [chart2Loading]);
  return (
    <div className="distributed-chart">
      <p className="chart-heading">Salary Distribution</p>
      {data.length > 0 ? (
        <div>
          <Line {...config} autoFit={true} height={300} />
        </div>
      ) : (
        <div className="graph-nodata-div">
          {chart2Loading ? (
            <LoadingOutlined className="loader primary-color" />
          ) : (
            <div>
              <img
                width={50}
                src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1715107707/chart_lbagty.png"
                alt=""
              />
              <p className="">No data</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SalarySalaryDistributionChart;
